import React, { useState, useEffect } from "react"
import "./Navbar.css"
// import { Link as ScrollLink } from "react-scroll";
import { AnchorLink as ScrollLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

const windowGlobal = typeof window !== "undefined" && window
function Navbar({ location }) {
  const [checked, setChecked] = useState(false)
  let path = location && location.pathname
  const handleChecked = e => {
    setChecked(!checked)
    document.body.style.overflowY = checked ? "" : "hidden"
  }
  const homepage = path === "/"

  const handleNav = () => {
    if (path === "/") {
      var main = document.querySelector(".showcase")
      var nav = document.querySelector(".main-nav")
      if (nav && main) {
        if (windowGlobal.pageYOffset > 120) {
          nav.classList.remove("overlay-nav")
          nav.classList.add("solid-nav")
        } else {
          nav.classList.add("overlay-nav")
          nav.classList.remove("solid-nav")
        }
      }
    }
  }

  useEffect(() => {
    handleNav()
    windowGlobal.addEventListener("scroll", handleNav)
  }, [])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="top-nav">
        <div className="left-top-nav">
          <div className="top-item hide-md">
            <i className="fa fa-map-marker"></i>
            <span>Kadaghari, Kathmandu</span>
          </div>
          <div className="top-item">
            <i className="fa fa-phone"></i>
            <span>9841419161</span>
          </div>
          <div className="top-item hide-md">
            <i className="fa fa-envelope"></i>
            <span>studiothapas@gmail.com</span>
          </div>
        </div>
        <div className="right-top-nav">
          <div className="top-link">
            <div className="top-link-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Studiothapasphotography/"
              >
                <i className="fa fa-facebook-square"></i>
              </a>
            </div>
            <div className="top-link-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/studiothapas/"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </div>
            <div className="top-link-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://za.pinterest.com/studiothapas/"
              >
                <i className="fa fa-pinterest-square"></i>
              </a>
            </div>
            <div className="top-link-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/studiothapas"
              >
                <i className="fa fa-twitter-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="navbar" className="main-nav">
        <div className="nav-wrapper nav-container">
          <div className="nav-header">
            <ScrollLink
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              to="/#home"
            >
              <h3>Wedding Thapas</h3>
            </ScrollLink>
          </div>
          <div className="nav-items">
            <h4 className="nav-link">
              <ScrollLink
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                to="/#home"
              >
                Home
              </ScrollLink>
            </h4>
            <h4 className="nav-link">
              <ScrollLink
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                to="/about"
              >
                About
              </ScrollLink>
            </h4>
            <h4 className="nav-link">
              <ScrollLink
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                to="/#service"
              >
                Services
              </ScrollLink>
            </h4>
            <h4 className="nav-link">
              <ScrollLink
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                to="/photo"
              >
                Photo
              </ScrollLink>
            </h4>
            <h4 className="nav-link">
              <a href="#video">Video</a>
            </h4>
            <h4 className="nav-link">
              <ScrollLink
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                to="/#contact"
              >
                Contact
              </ScrollLink>
            </h4>
            <div className="menu-wrap">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChecked}
                className="toggler"
              />
              <div className={`hamburger ${checked && "open" } `}>
                <div />
              </div>
              <div className="menu">
                <div>
                  <div>
                    <ul>
                      <li>
                        <a onClick={handleChecked} href="/#home">
                          Home
                        </a>
                      </li>
                      <li>
                        <a onClick={handleChecked} href="/about">
                          About
                        </a>
                      </li>
                      <li>
                        <a onClick={handleChecked} href="/#service">
                          Services
                        </a>
                      </li>
                      <li>
                        <Link onClick={handleChecked} to="/photo">
                          Photos
                        </Link>
                      </li>
                      <li>
                        <a onClick={handleChecked} href="/#video">
                          Videos
                        </a>
                      </li>
                      <li>
                        <a onClick={handleChecked} href="/#contact">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
