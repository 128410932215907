import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div id="footer">
      <div className="main-footer clearfix">
        <div className="info">
          <h2 className="logo">Studio Thapas</h2>
          <div className="location">
            <i className="fa fa-map-marker"></i>
            <span>Kadaghari, Kathmandu</span>
          </div>
          <div className="contact">
            <i className="fa fa-phone"></i>
            <span>9841419161</span>
          </div>
          <div className="femail">
            <i className="fa fa-envelope"></i>
            <span>studiothapas@gmail.com</span>
          </div>
        </div>
        <div className="footer-nav">
          <h3 className="footer-header">Top Links</h3>
          <a href="#navbar">Home</a>
          <a href="#about">About </a>
          <a href="#photo">Photo gallery</a>
          <a href="#video">Video</a>
        </div>
        <div className="facebook-footer">
          <h3 className="footer-header">Facebook</h3>
          <iframe title="Studio thapas " src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FStudiothapasphotography%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=530254630983749" width="340" height="280" style={{border:"none",overflow:"hidden"}} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>
    </div>
  );
}
