import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            googleSiteVerification
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "YVArXKJ6Jv4apd4VhiJSvhgguNDAVzcPvXy3P0mkJJE",
        },
        {
          name: "googlebot",
          content: "index, follow",
        },
        {
          name: "Keywords",
          content:
            "wedding photography,  Cinematography,  studio portrait,  photo book,  Engagement, Wedding Film,  Receptions video.  Pre Wedding,  Post Wedding,  rice feeding,  maternity shoot, Baby Shoot,  Event photography,  corporate events, best videography, puskal thapa, photo service",
        },
        {
          name: "author",
          content: "Wedding Thapas | Studio Thapas | Puskal Thapas",
        },
        {
          name: "allow-search",
          content: "yes",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat()}
    />
  )
}

export default SEO
